import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Footer from '../components/footer'

const IndexPage = () => (
  <>
    <Layout>
      <Seo title="About" />
      <header className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div className="sm:text-center lg:text-left">
          <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl">
            <span className="block text-charcoal xl:inline">Providing real estate advisory service</span>{' '}
            <span className="block text-seagreen xl:inline">for the Detroit metro and greater area</span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            If you are looking for real estate investment opportunities in metro Detroit's retail housing market. Let me be your advisor. Together we can expand your housing investment opportunities by pursuing them outside of the common marketplace.
          </p>
          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow">
              <Link
                to="/contact"
                className="btn btn-primary"
              >
                Contact Me
              </Link>
            </div>
          </div>
        </div>
      </header>
    </Layout>
    <main id="about" className="bg-aquamarine bg-opacity-20 py-28">
      <div className="container mx-auto px-6 lg:p-0">
        <h2 className="text-3xl py-6 text-xl text-center text-charcoal">About</h2>
        <div className="w-full text-charcoal">
          <p className="py-2">
            Currently the retail housing market (homes sold via the MLS) presents limited opportunities for investors.  Thus, due to the lack of high yield investment homes, the Lakepointe Group will focus on investments in Sheriff’s Auction homes, Pre-Foreclosure homes, and post Sherriff’s Auction homes, in addition to pursing homes found on the MLS.  Auctions take place on a weekly basis in each county throughout the state.
          </p>
          <p className="py-2">
            Foreclosure/Auction homes require all cash purchases, however homes that meet the investment criteria present tremendous opportunity to acquire property well below market value. 
          </p>
          <p className="py-2">
            Buying homes at auction requires significant levels of due diligence.  Much of the analysis will be done using research tools such as the MLS as well as its public record database.  Lakepointe Group will identify homes that will be auctioned, underwrite the property and identify if the deal can be profitable by debt/lien analysis, comparing sales, conditions, locations, and all in costs.
          </p>
          <p className="py-2">
            Overall, the Lakepointe Group wishes to partner with investors who see opportunity to acquire property below market value.  The Lakepointe Group will manage the due diligence, acquisition, rehabilitation, and disposition of each asset.
          </p>
        </div>
      </div>
    </main>
    <Footer />
  </>
)

export default IndexPage
